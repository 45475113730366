import { ReactElement, useEffect, useState, useMemo, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Card, ButtonV2, Typography } from '@vartanainc/design-system';
import { camelCase, get } from 'lodash';

import { GET_CUSTOMER_FOR_MORE_INFO } from '../../graphql/queries/customer';
import {
  ARCHIVE_DOCUMENTS,
  MOVE_CREDIT_APPLICATION_TO_NEXT_STEP,
  MOVE_CREDIT_APPLICATION_TO_PREVIOUS_STEP,
  UPDATE_AUTHORIZED_SIGNER,
  UPDATE_BUSINESS_DETAILS_V2,
  UPDATE_CREDIT_APPLICATION_SALES_QUOTE,
} from '../../graphql/queries/creditCheck';
import { BusinessDetailsForm } from './Forms/BusinessDetailsForm';
import { AuthSignerForm } from './Forms/AuthSignerForm';
import { FinancialDocumentsForm } from './Forms/FinancialDocumentsForm';
import { StepDetailsWrapper } from './StepDetails/StepDetailsWrapper';
import { SalesQuoteForm } from './Forms/SalesQuoteForm';

import AutoLoad from '../../components/AutoLoad';
import './MoreInfo.scss';
import {
  formatCapital,
  getPageUrl,
  isRenderedFromHubspot,
  showToast,
} from '../../utils/helpers';
import { useAttachFinancialDocs, useDirectUploadFiles } from '../../utils/hooks';
import CancelConfirmationModal from '../../components/Modals/CancelConfirmation';
import { EIN_PLACEHOLDER, HUBSPOT_QUERY_PARAM } from '../../constants/common.constants';
import LoaderMd from '../../components/LoaderMd/LoaderMd';
import SvgIcon from '../../components/SvgIcon/SvgIcon';
import { COUNTRY } from '../../static';

interface ModifyTermsProps {
  setLoading: (loading: boolean) => void;
  setCompanyName: (companyName: string) => void;
  onSuccess?: () => void;
}

const page = {
  authSigner: 'authorized-signer',
  bussinesDetails: 'business-details',
  financialDetails: 'financial-documents',
  salesQuote: 'sales-quote',
};

const ctaLabels = {
  next: 'Next',
  submit: 'Submit',
};
const applicationSentState = 'application_forwardedsdv';

export function MoreInfoWrapper({
  setLoading,
  setCompanyName,
  onSuccess = () => null,
}: ModifyTermsProps): ReactElement {
  const { companyNumber: paramCompanyNumber, stepType } = useParams();
  const location = useLocation();

  const navigate = useNavigate();
  const isExpandedApp = location.pathname.includes('forms/more-info');
  const useQueryParams = new URLSearchParams(location.search);
  const companyNumber = isExpandedApp
    ? useQueryParams.get('companyNumber')
    : paramCompanyNumber;

  const [formLoading, setFormLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [removedFiles, setRemovedFiles] = useState<string[]>([]);
  const [showButtonLoader, setShowButtonLoader] = useState(false);

  const formRef = useRef(null);
  const submitBtnRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  //  TODO: this is to be discussed with Daphne, can be commented until then
  // const autoScrollFormIntoView = (): void => {
  //   if (submitBtnRef.current) {
  //     submitBtnRef.current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //     });
  //   }
  // };

  const [getCustomerData, { data: customerData, loading: customerLoading }] =
    useLazyQuery(GET_CUSTOMER_FOR_MORE_INFO, {
      variables: {
        number: companyNumber,
      },
      onCompleted: () => {
        // we should start showing button loader only after customer data is fetched
        if (!showButtonLoader) setShowButtonLoader(true);
      },
    });

  const [attachFinancialDocs, { loading: attachingFinancialDocs }] =
    useAttachFinancialDocs() as [
      (docs, docsMeta, cmpNumber) => Promise<unknown>,
      { loading: boolean }
    ];
  const [attachSalesQuote, { loading: attachingSalesQuote }] = useDirectUploadFiles() as [
    (docs, docsMeta, cmpNumber) => Promise<unknown>,
    { loading: boolean }
  ];

  const [updateAuthSigner, { loading: updatingAuthSigner }] = useMutation(
    UPDATE_AUTHORIZED_SIGNER
  );
  const [updateBusinessDetails, { loading: updatingBusinessDetails }] = useMutation(
    UPDATE_BUSINESS_DETAILS_V2
  );
  const [moveToNextStep, { loading: movingToNextStep }] = useMutation(
    MOVE_CREDIT_APPLICATION_TO_NEXT_STEP
  );
  const [moveToPreviousStep, { loading: movingToPreviousStep }] = useMutation(
    MOVE_CREDIT_APPLICATION_TO_PREVIOUS_STEP
  );
  const [archiveDocs, { loading: archivingDocs }] = useMutation(ARCHIVE_DOCUMENTS);
  const [updatesSalesQuote] = useMutation(UPDATE_CREDIT_APPLICATION_SALES_QUOTE);

  const applicationSteps = useMemo(() => {
    return customerData?.company?.creditCheck?.creditApplication?.applicationSteps?.map(
      (step) => {
        return {
          ...step,
          page: step.name?.replace('_', '-'),
        };
      }
    );
  }, [customerData]);

  const financialDocs = useMemo(() => {
    return customerData?.company?.creditCheck?.creditApplication?.financialDocs || [];
  }, [customerData]);

  const otherParams = isRenderedFromHubspot() ? `&${HUBSPOT_QUERY_PARAM}` : '';

  const isPersonalGuaranty = useMemo(() => {
    const isPgApplication = get(
      customerData,
      'company.creditCheck.creditApplication.applicationSteps',
      []
    ).find((step) => step.name === 'personal_guaranty');

    // double negation to convert value to valid boolean
    return !!isPgApplication;
  }, [customerData]);

  useEffect(() => {
    getCustomerData();
  }, [getCustomerData]);

  useEffect(() => {
    // If the application is in pending state we auto move to the next step
    const applicationProgress = get(
      customerData,
      'company.creditCheck.creditApplication.progress',
      ''
    );

    if (applicationProgress === 'pending' && !customerLoading && !isPersonalGuaranty) {
      moveToNextStep({
        variables: {
          customerNumber: companyNumber,
        },
      }).then(() => {
        getCustomerData();
      });
    }
  }, [
    customerData,
    customerLoading,
    companyNumber,
    getCustomerData,
    moveToNextStep,
    isPersonalGuaranty,
  ]);

  useEffect(() => {
    // redirect to correct page if wrong page is opened
    const applicationProgress = get(
      customerData,
      'company.creditCheck.creditApplication.progress',
      ''
    ).replace('_', '-');
    const applicationState = get(
      customerData,
      'company.creditCheck.creditApplication.state',
      ''
    );

    if (
      applicationProgress &&
      applicationProgress !== 'pending' &&
      stepType !== applicationProgress
    ) {
      if (isExpandedApp)
        navigate(
          getPageUrl({
            customerNumber: companyNumber || '',
            expandedApp: true,
            moreInfoStep: applicationProgress,
            otherParams,
            page: 'moreInfo',
          })
        );
      else
        navigate(
          getPageUrl({
            customerNumber: companyNumber || '',
            moreInfoStep: applicationProgress,
            page: 'moreInfo',
          })
        );
    }

    // If credit application does not exist, redirect to customer summary screen
    const shouldRedirect =
      (customerData && !customerData.company?.creditCheck?.creditApplication) ||
      applicationProgress === 'completed' ||
      isPersonalGuaranty ||
      (applicationState === applicationSentState &&
        customerData?.company?.creditCheck?.creditApplication
          ?.customerApplicationOpenedAt);

    if (shouldRedirect)
      if (isExpandedApp) onSuccess();
      else navigate(`/dashboard/customers/${companyNumber}/summary`);
  }, [
    customerData,
    companyNumber,
    isExpandedApp,
    navigate,
    stepType,
    customerLoading,
    onSuccess,
    isPersonalGuaranty,
    otherParams,
  ]);

  useEffect(() => {
    // set loading to false if customerLoading is false, this is to handle loader in the parent component
    if (!customerLoading && customerData) {
      setLoading(false);
    }
  }, [customerLoading, setLoading, customerData]);

  useEffect(() => {
    setCompanyName(get(customerData, 'company.name', ''));
  }, [customerData, setCompanyName]);

  const loading =
    customerLoading ||
    updatingAuthSigner ||
    updatingBusinessDetails ||
    movingToNextStep ||
    movingToPreviousStep ||
    attachingFinancialDocs ||
    attachingSalesQuote ||
    archivingDocs ||
    formLoading;

  const loader = useMemo((): ReactElement => {
    return (
      <div className="h-5 w-12 py-[0.125rem] flex justify-center bg-vartana-blue-60">
        <LoaderMd color="white" containerClass="w-4 h-4" />
      </div>
    );
  }, []);

  const nextCTALabel = useMemo((): string | ReactElement => {
    const showSubmit =
      (applicationSteps || []).findIndex((step) => step?.page === stepType) ===
      (applicationSteps || []).length - 1;
    if (showSubmit) {
      return ctaLabels.submit;
    }
    return ctaLabels.next;
  }, [stepType, applicationSteps]);

  const backCTALabel = useMemo((): string => {
    const showCancel =
      (applicationSteps || []).findIndex((step) => step?.page === stepType) === 0;
    if (showCancel) {
      return 'Cancel';
    }
    return 'Back';
  }, [stepType, applicationSteps]);

  const handleBack = async (): Promise<void> => {
    // If the form is dirty and user tries to navigate back, show unsaved changes modal
    if (get(formRef, 'current.dirty', false) && !showUnsavedChangesModal) {
      setShowUnsavedChangesModal(true);
      return;
    }
    if (backCTALabel === 'Cancel') {
      navigate(`/dashboard/customers/${companyNumber}/summary`);
      return;
    }
    // we should not show button loader when moving to previous step
    if (showButtonLoader) setShowButtonLoader(false);

    // If the handleBack is changing the form screen and modal was open, we need to close it
    if (showUnsavedChangesModal) setShowUnsavedChangesModal(false);

    await moveToPreviousStep({
      variables: {
        customerNumber: companyNumber,
      },
    });
    await getCustomerData();
    const previousPage =
      (applicationSteps || []).findIndex((step) => step?.page === stepType) - 1;

    if (isExpandedApp)
      navigate(
        getPageUrl({
          customerNumber: companyNumber || '',
          expandedApp: true,
          moreInfoStep: applicationSteps[previousPage]?.page,
          otherParams,
          page: 'moreInfo',
        })
      );
    else
      navigate(
        getPageUrl({
          customerNumber: companyNumber || '',
          moreInfoStep: applicationSteps[previousPage]?.page,
          page: 'moreInfo',
        })
      );
  };

  const handleNext = async (): Promise<void> => {
    await moveToNextStep({
      variables: {
        customerNumber: companyNumber,
      },
    });
    await getCustomerData();

    if (nextCTALabel === ctaLabels.submit) {
      if (!isExpandedApp) {
        navigate(`/dashboard/customers/${companyNumber}/summary`);
        const successMessage =
          'We have successfully received your submission. Hang tight while we review the information.';
        showToast('success', successMessage);
      }
      onSuccess();
    } else {
      const nextPage = applicationSteps?.findIndex((step) => step?.page === stepType) + 1;

      if (isExpandedApp)
        navigate(
          getPageUrl({
            customerNumber: companyNumber || '',
            expandedApp: true,
            moreInfoStep: applicationSteps[nextPage]?.page,
            otherParams,
            page: 'moreInfo',
          })
        );
      else
        navigate(
          getPageUrl({
            customerNumber: companyNumber || '',
            moreInfoStep: applicationSteps[nextPage]?.page,
            page: 'moreInfo',
          })
        );
    }
  };

  const assembleDocs = (): {
    docs: { docs: string }[];
    docsMeta: {
      notes: string;
      metaArray: { externalId: string; documentType: string }[];
    };
  } => {
    // This method is used to flatten the docs array and return the notes and metaArray
    if (stepType !== 'financial-documents')
      return { docs: [], docsMeta: { notes: '', metaArray: [] } };
    const flattenedDocs = [];

    // metaArray is used to store the externalId and documentType of all the docs
    const metaArray = [] as { externalId: string; documentType: string }[];
    Object.keys(get(formRef, 'current.values', {}))
      ?.filter(
        (key) => key !== 'notes' && get(formRef, `current.values.${key}`, [])?.length > 0
      )
      ?.forEach((key) => {
        get(formRef, `current.values.${key}`, []).forEach((doc) => {
          // only push to flattenedDocs if the doc has a size, meaning it's a new file
          if (get(doc, 'size', '')) {
            flattenedDocs.push(doc);
            metaArray.push({
              externalId: key,
              documentType:
                financialDocs.find((fDoc) => fDoc.externalId === key)?.type || '',
            });
          }
        });
      });

    return {
      docs: flattenedDocs,
      docsMeta: {
        notes: get(formRef, 'current.values.notes', ''),
        metaArray,
      },
    };
  };

  const handleSubmit = async (): Promise<void> => {
    const formikBag = formRef.current;
    const applicationStep = applicationSteps?.find((step) => step.page === stepType);
    const dirty = get(formikBag, 'dirty', false);
    let submissionResponse;
    const setErrors = get(formikBag, 'setErrors', (e) => null);
    const financialNotes =
      get(
        customerData,
        'company.creditCheck.creditApplication.financialDocsAdditionalNotes',
        ''
      ) || '';
    const ein =
      get(formikBag, 'values.ein') === EIN_PLACEHOLDER
        ? ''
        : get(formikBag, 'values.ein', '');

    const { docs, docsMeta } = assembleDocs();
    // only submit step form either if it's pending or there're new changes
    if (applicationStep.status === 'pending' || dirty) {
      switch (stepType) {
        case page.authSigner:
          submissionResponse = await updateAuthSigner({
            variables: {
              customerNumber: companyNumber,
              authorizedSignerDetails: {
                street: get(formikBag, 'values.address', ''),
                city: get(formikBag, 'values.city', ''),
                email: get(formikBag, 'values.email', ''),
                firstName: get(formikBag, 'values.firstName', ''),
                jobTitle: get(formikBag, 'values.jobTitle', ''),
                lastName: get(formikBag, 'values.lastName', ''),
                phone: get(formikBag, 'values.phone', '').includes('+1')
                  ? get(formikBag, 'values.phone', '')
                  : `+1${get(formikBag, 'values.phone', '')}`,
                state: get(formikBag, 'values.state', ''),
                zip: get(formikBag, 'values.zip', ''),
              },
            },
          });
          break;

        case page.bussinesDetails:
          submissionResponse = await updateBusinessDetails({
            variables: {
              customerNumber: companyNumber,
              businessName: get(formikBag, 'values.businessName', ''),
              ein,
              street: get(formikBag, 'values.street', ''),
              city: get(formikBag, 'values.city', ''),
              country: get(formikBag, 'values.country', ''),
              state: get(formikBag, 'values.state', ''),
              zip: get(formikBag, 'values.zip', ''),
            },
          });
          break;

        case page.financialDetails:
          // only call attachFinancialDocs if there are new files or notes have changed
          if (docs.length || docsMeta.notes !== financialNotes)
            await attachFinancialDocs(docs, docsMeta, companyNumber);
          if (removedFiles.length)
            await archiveDocs({
              variables: {
                documentIds: removedFiles,
              },
            });
          break;

        case page.salesQuote:
          await attachSalesQuote(
            get(formikBag, 'values.salesQuote', []),
            {
              documentType: 'sales_quote',
            },
            {
              id: get(customerData, 'company.creditCheck.creditApplication.id', null),
              type: '::CreditEngine::CreditApplication',
            }
          );
          if (get(formikBag, 'values.notes', ''))
            await updatesSalesQuote({
              variables: {
                customerNumber: companyNumber,
                note: get(formikBag, 'values.notes', ''),
              },
            });
          break;

        default:
          break;
      }
    }

    // If any errors are returned from the mutation, set them in the form
    if (submissionResponse?.errors) {
      setErrors(submissionResponse?.errors);
      return;
    }

    handleNext();
  };

  const isStepPending = (step: string): boolean => {
    return applicationSteps?.find((s) => s.page === step)?.status === 'pending';
  };

  const handleFormRender = (): ReactElement | null => {
    const formProps = {
      handleSubmit,
      formRef,
      setSubmissionDisabled: setSubmitDisabled,
    };
    const financialNotes =
      get(
        customerData,
        'company.creditCheck.creditApplication.financialDocsAdditionalNotes',
        ''
      ) || '';

    // fetch the alert text for the current step, we need to convert stepType to camelCase since stepType in path params is dash-cased
    const stepAlertText = get(
      customerData,
      `company.creditCheck.creditApplication.applicationStepsDetails.${camelCase(
        stepType
      )}.rejectionReason`,
      ''
    );
    const hideCompanyTaxID = get(customerData, 'company.country', '') === COUNTRY.CA;

    const authSignerDefaultValues =
      !customerData?.company?.primaryUser || isStepPending(page.authSigner)
        ? {}
        : customerData?.company?.primaryUser;
    const businessDetailProps = isStepPending(page.bussinesDetails)
      ? null
      : {
          businessName: formatCapital(get(customerData, 'company.businessName', '')),
          ein: customerData?.company?.entityInformationEin || '',
          street: get(customerData, 'company.street', ''),
          city: get(customerData, 'company.city', ''),
          state: get(customerData, 'company.state', ''),
          zip: get(customerData, 'company.zip', ''),
          country: get(customerData, 'company.country', ''),
        };

    switch (stepType) {
      case page.authSigner:
        return (
          <AuthSignerForm
            authSigner={authSignerDefaultValues}
            {...formProps}
            alertText={stepAlertText}
            customerNumber={companyNumber || ''}
          />
        );
      case page.bussinesDetails:
        return (
          <BusinessDetailsForm
            businessDetailProps={
              isStepPending(page.bussinesDetails) ? null : businessDetailProps
            }
            {...formProps}
            alertText={stepAlertText}
            hideCompanyTaxId={hideCompanyTaxID}
          />
        );
      case page.financialDetails:
        return (
          <FinancialDocumentsForm
            financialDocs={financialDocs}
            setRemovedFiles={setRemovedFiles}
            notes={financialNotes}
            alertText={stepAlertText}
            {...formProps}
          />
        );
      case page.salesQuote:
        return <SalesQuoteForm {...formProps} />;
      default:
        return null;
    }
  };

  const showContainerLoader = loading && !showButtonLoader;
  const renderButtonLoader = loading && showButtonLoader;
  const onSubmitClick = (): void => {
    setFormLoading(true);
    handleSubmit().finally(() => {
      setFormLoading(false);
      setRemovedFiles([]);
    });
  };

  return (
    <div className="more-info-root-container flex flex-col self-center pb-24">
      <CancelConfirmationModal
        open={showUnsavedChangesModal}
        onConfirm={handleBack}
        onClose={() => setShowUnsavedChangesModal(false)}
      />
      <div className="m-auto">
        <div className="flex flex-col gap-[0.625rem] mt-10 mb-8">
          <div className=" flex flex-row gap-2 items-center">
            <SvgIcon name="demography" className="h-8 w-8" fill="color-gold-100" />
            <Typography
              className="leading-normal"
              variant="heading20"
              color="color-black-100"
            >
              We need additional information
            </Typography>
          </div>
          <Typography variant="paragraph14" color="color-black-60">
            Please provide the following details to proceed with the payment options
            review. You can also share the link with your customer for completion.
          </Typography>
        </div>
        <div className="h-100">
          <Card
            variant="variable"
            parentContainerClassName="more-info-card-container relative"
            containerClassName="more-info-card-content min-h-[26.75rem] min-w-[64.5rem]"
            content={(
              <div ref={submitBtnRef}>
                <AutoLoad
                  loading={showContainerLoader}
                  containerClassName="absolute top-[calc(50%-1.25rem)] left-[calc(50%-1.25rem)]"
                />
                {customerData && (
                  <div
                    className={`flex flex-row gap-10 divide-x ${
                      showContainerLoader ? 'hidden' : ''
                    }`}
                  >
                    <div className="min-w-[36.5rem]">{handleFormRender()}</div>
                    <div className="flex flex-col gap-8 pl-10 max-w-[20.5rem] py-10">
                      <StepDetailsWrapper
                        steps={applicationSteps}
                        activeStep={stepType || ''}
                        stepsMeta={
                          customerData?.company?.creditCheck?.creditApplication
                            ?.applicationStepsDetails
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          />
          <div className="btn-container flex flex-row justify-between items-center pt-10 w-full">
            {loading && <div className="btn-overlay" />}
            {(!isExpandedApp || backCTALabel === 'Back') && (
              <div>
                <ButtonV2
                  className="back-btn"
                  variant={{
                    type: 'ghost',
                    typography: 'paragraph14',
                  }}
                  type="button"
                  text={backCTALabel}
                  iconLeft="chevron_left"
                  onClick={handleBack}
                />
              </div>
            )}
            <ButtonV2
              className={`submit-btn ml-auto ${
                nextCTALabel === ctaLabels.submit ? 'min-w-[6rem]' : 'min-w-[6.75rem]'
              }`}
              type="button"
              disabled={submitDisabled}
              text={(renderButtonLoader ? loader : nextCTALabel) as string}
              iconRight={
                nextCTALabel !== ctaLabels.next || renderButtonLoader
                  ? ''
                  : 'chevron_right'
              }
              variant={{ type: 'primary', typography: 'paragraph14' }}
              onClick={onSubmitClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
