// sample test payloads for widgetUtils.tsx, this will be removed once endpoint is implemented

import { ButtonV2, Typography } from '@vartanainc/design-system';
import { useContext } from 'react';
import { get } from 'lodash';
import { twMerge } from 'tailwind-merge';
import {
  commonRegex,
  HUBSPOT_QUERY_PARAM,
  PROGEN_CTA,
  WIDGET_WIDTH_VARIANTS,
} from '../../../constants/common.constants';
import { WidgetContext } from '../../../context/WidgetContext';
import usePermissions from '../../../utils/hooks/permissions';
import { progenDefaultTexts } from '../../../constants/progenTexts';
import {
  PAYMENT_OPTION_CTA_COMPACT_TEXT,
  WIDGET_TEXT_CONSTANTS,
} from './widgetV2.constants';
import SvgIcon from '../../../components/SvgIcon/SvgIcon';
import { windowExtended } from '../../../utils/commonInterfaces';

type actionProp = {
  label: string;
  type: string;
  slug: string;
};

export const TertiaryActions = ({
  actions,
  onActionClick,
}: {
  actions: actionProp[];
  onActionClick: (action: actionProp) => void;
}): JSX.Element => {
  const [, hasPermission] = usePermissions();
  const widgetContext = useContext(WidgetContext);
  const widthVariant = get(widgetContext, 'widthVariant', '');
  const selectedCompany = get(widgetContext, 'selectedCompany', {});

  /**
   * Handles the variation of a label based on screen size and label type
   * @param slug - The slug for the action.
   * @param label - The label to be handled.
   * @returns The updated label with the first character capitalized and '-' replaced with a space.
   */
  const handleLabelVariation = (slug: string, label: string): string => {
    const updatedLabel =
      slug === PROGEN_CTA.creditInfo && widthVariant === WIDGET_WIDTH_VARIANTS.sm
        ? PAYMENT_OPTION_CTA_COMPACT_TEXT
        : label;
    return updatedLabel.charAt(0).toUpperCase() + updatedLabel.replace('-', ' ').slice(1);
  };

  return (
    <div className="flex flex-row gap-2 items-center">
      {actions?.map(({ label, type, slug }, index) => {
        if (
          (slug === PROGEN_CTA.modifyTerms || slug === PROGEN_CTA.cancelRequest) &&
          !hasPermission('order', 'request_term_modification', selectedCompany)
        )
          return <></>;

        return (
          <div key={`${label}-${type}`} className="flex flex-row items-center">
            {index !== 0 && (
              <div className="bg-vartana-gray-50 h-4 w-[0.063rem] mr-2 my-0" />
            )}

            <ButtonV2
              variant={{ type: 'text', typography: 'paragraph12' }}
              text={handleLabelVariation(slug, label)}
              onClick={() => {
                onActionClick({ label, type, slug });
              }}
              className="tertiary-cta-buttons"
              style={{ height: 'min-content' }}
            />
          </div>
        );
      })}
    </div>
  );
};

export type actionType = {
  label: string;
  type: string;
  isEnabled: boolean;
  slug: string;
};

export const getPillStatus = (status: string): 'approved' | 'review' | 'info' => {
  switch (status) {
    case 'IN REVIEW':
      return 'review';
    case 'CREDIT REVIEW':
      return 'review';
    case 'APPROVED':
      return 'approved';
    case 'MISSING INFORMATION':
      return 'info';
    case 'NEED INFORMATION':
      return 'info';
    default:
      return 'review';
  }
};

// TODO: remove getActions once endpoint supports ordered buttons
export const getActions = (actions: actionType[]): actionType[] => {
  // this sorts primary and secondary button order
  return actions
    ?.filter((action) => action.type !== 'tertiary')
    .sort((a, b) => b.type.localeCompare(a.type));
};

export const CUSTOMER_FORM_STEPS = {
  customerInfo: 1,
  documentUpload: 2,
};

export const getCustomerFormActions = (
  canSubmit: boolean,
  customerFormStep: number
): actionType[] => {
  const isFinalFormStep = customerFormStep === CUSTOMER_FORM_STEPS.documentUpload;
  return [
    {
      type: 'primary',
      slug: 'submit',
      label: isFinalFormStep ? 'Submit' : 'Continue',
      isEnabled: canSubmit,
    },
  ];
};

export const isStatusPage = (status: string): boolean => {
  return (
    status === 'PRICING EXPIRED' ||
    status === 'EXPIRED' ||
    status === 'DECLINED' ||
    status === ''
  );
};

export const handleTrackRequest = (
  isCrm: boolean,
  companyNumber: string,
  crmOpportunityId: string,
  navigate: (path: string) => void
): void => {
  if (isCrm)
    window.open(
      `/forms/summary?customerNumber=${companyNumber}&crmOpportunityId=${crmOpportunityId}&tab=summary`
    );
  else navigate(`/dashboard/customers/${companyNumber}/track`);
};

export const renderPgStatus = (
  pgRequired: boolean,
  classNames?: string,
  color = 'color-black-100',
  isSmallTypography?: boolean
): JSX.Element => {
  /**
   * Renders the PG required text based on screen size and word break.
   * @returns The JSX element containing the PG required text.
   */
  const getPgRequiredText = (): JSX.Element => {
    // if its small screen, break the text after 'Personal'
    // else allow default text break
    const pgText = progenDefaultTexts.pg_description.split('\n');
    return (
      <Typography variant="paragraph10" color={color} className="mr-[0.125rem]">
        {isSmallTypography ? (
          <>
            {pgText[0]}
            <br />
            {pgText[1]}
          </>
        ) : (
          progenDefaultTexts.pg_description
        )}
      </Typography>
    );
  };

  if (pgRequired)
    return (
      <div className={twMerge('flex flex-row items-center pg-required-text', classNames)}>
        {getPgRequiredText()}
        <div className="relative flex items-end">
          <SvgIcon
            name="info-filled"
            fill="color-gray-80"
            height="0.75rem"
            width="0.75rem"
            className="tooltip-icon"
          />

          <div
            className="tooltip pg-tooltip flex p-[0.75rem] jutify-end items-end self-stretch gap-[0.2rem]
            bg-vartana-gray-70 rounded shadow-md absolute left-[-2.375rem] bottom-[1.563rem] w-[14.3125rem]"
          >
            <Typography variant="paragraph12" color="color-white-100">
              {progenDefaultTexts.calculator_pg_tooltip}
            </Typography>
          </div>
        </div>
      </div>
    );
  return <></>;
};

// This component renders a tooltip with additional information about calculator fees
export const renderFeeTooltip = (
  documentationFee: string,
  discountTooltip: string,
  isSmallScreen?: boolean
): JSX.Element => {
  return (
    <div className="flex justify-center items-center relative">
      {/* Tooltip icon */}
      <SvgIcon
        name="info-filled"
        fill="color-gray-80"
        width="0.75rem"
        height="0.75rem"
        className="tooltip-icon"
      />
      {/* Tooltip content */}
      <div className="tooltip flex p-3 justify-end items-end self-stretch bg-[#333333] rounded shadow-md absolute bottom-[1.7rem] left-[-2.40rem] max-w-[14.3125rem] w-max">
        <Typography
          variant={isSmallScreen ? 'paragraph10' : 'paragraph12'}
          color="color-white-100"
          className="whitespace-pre-line"
        >
          {/* Payout after */}
          {WIDGET_TEXT_CONSTANTS.PAYOUT_AFTER}
          {/* Display documentation fee if available */}
          {!!documentationFee && (
            <span>
              <br />
              {`- ${documentationFee}`}
            </span>
          )}
          {/* Display discount tooltip if available */}
          {!!discountTooltip && (
            <span>
              <br />
              {`- ${discountTooltip}`}
            </span>
          )}
        </Typography>
      </div>
    </div>
  );
};

export const handlePageNavigate = (
  url: string,
  isRenderedFromHubspot: boolean,
  navigate: (path: string) => void
): null | windowExtended => {
  if (isRenderedFromHubspot) {
    // check if the url already has the hubspot query param otherwise add it
    const additionalParams = url.includes(HUBSPOT_QUERY_PARAM)
      ? ''
      : `&${HUBSPOT_QUERY_PARAM}`;
    navigate(`${url}${additionalParams}`);
  } else {
    window.open(url);
    // return window object to be used in the parent window
    return window as unknown as windowExtended;
  }
  return null;
};

/**
 * Checks if the screen is compact based on the width variant and CRM status.
 * @param widthVariant - The width variant of the widget.
 * @param isCrm - Indicates if the widget is in CRM mode.
 * @returns True if the screen is compact(sm, md or lg), false otherwise.
 */
export const isCompactScreen = (widthVariant: string, isCrm: boolean): boolean => {
  return isCrm && widthVariant !== WIDGET_WIDTH_VARIANTS.xl;
};

/**
 * Checks if the screen is small variant based on the width variant and CRM status.
 * @param widthVariant - The width variant of the widget.
 * @param isCrm - Indicates if the widget is in CRM mode.
 * @returns True if the screen is small(sm), false otherwise.
 */
export const isSmallScreen = (widthVariant: string, isCrm: boolean): boolean => {
  return isCrm && widthVariant === WIDGET_WIDTH_VARIANTS.sm;
};

/**
 * Checks if the screen is extra large variant based on the width variant and CRM status.
 * @param widthVariant - The width variant of the widget.
 * @param isCrm - Indicates if the widget is in CRM mode.
 * @returns True if the screen is extra large (xl), false otherwise.
 */
export const isExtraLargeScreen = (widthVariant: string, isCrm: boolean): boolean => {
  return isCrm && widthVariant === WIDGET_WIDTH_VARIANTS.xl;
};

/**
 * Returns the class names for the payout container based on the error and loading state.
 * @param error - Indicates if there is an error.
 * @param isLoading - Indicates if the data is loading.
 * @returns The class names for the payout container.
 */
export const getPayoutContainerClassNames = (
  error: boolean,
  isLoading: boolean,
  isCustomSchedule: boolean
): string => {
  const defaultClassNames = 'flex flex-col justify-end h-full';
  if (isCustomSchedule) {
    return twMerge(defaultClassNames, 'justify-center');
  }
  if (error)
    return isLoading ? twMerge(defaultClassNames, 'justify-between items-center') : '';

  if (isLoading) return twMerge(defaultClassNames, 'justify-center items-center');
  return defaultClassNames;
};

/**
 * Handles the download of a file.
 * @param file - The file to download.
 * @param fileName - The name of the file.
 */
export const handleDownload = (file: string, fileName: string): void => {
  // Check if `document` and `document.createElement` exist and is not run for non browser env
  if (typeof document === 'undefined' || typeof document.createElement !== 'function') {
    return;
  }

  const downloadLink = document.createElement('a');
  const linkSource = `data:application/pdf;base64,${file}`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

/**
 * Formats the amount to a 2 decimal number.
 * @param amount - The amount to format.
 * @returns The formatted amount.
 */
export const formatAmount = (amount: string): number => {
  return +parseFloat(amount.replace(commonRegex.removeDollarCommas, '')).toFixed(2);
};

/**
 * Function to get the maximum number of options to open the interest rate dropdown on top
 * based on the width variant, installment status, and spiff and subsidy allowance.
 *
 * @param {string} widthVariant - The width variant of the widget.
 * @param {boolean} isInstallment - Whether the installment option is selected.
 * @param {boolean} isSpiffAndSubsidyAllowed - Whether spiff and subsidy are allowed.
 * @returns {number} - The maximum number of options. 10 being the upper limit to show dropdown below
 */
export const getMaxInterestRateDropdownOptions = (
  widthVariant: string,
  isInstallment: boolean,
  isSpiffAndSubsidyAllowed: boolean
): number => {
  let maxOptionsCount = 0;
  switch (widthVariant) {
    case WIDGET_WIDTH_VARIANTS.sm:
      if (isSpiffAndSubsidyAllowed) {
        maxOptionsCount = isInstallment ? 1 : 3;
      } else maxOptionsCount = isInstallment ? 3 : 10;
      break;

    case WIDGET_WIDTH_VARIANTS.md:
    case WIDGET_WIDTH_VARIANTS.lg:
      if (isSpiffAndSubsidyAllowed) {
        maxOptionsCount = isInstallment ? 2 : 4;
      } else maxOptionsCount = isInstallment ? 4 : 10;
      break;

    case WIDGET_WIDTH_VARIANTS.xl:
      maxOptionsCount = 10;
      break;

    default:
      return 4;
  }

  return maxOptionsCount;
};
