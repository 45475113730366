// This file contains the constants used in the widgetV2 component

import { ACTION, RESOURCE } from '../../../constants/permissions';

// This interface represents the response from the calculator
export interface CalculatorResponseProps {
  payment: string; // The calculated payment amount
  subsidy: string; // The calculated subsidy amount
}

export type WidgetWidthVariantType = 'sm' | 'md' | 'lg' | 'xl' | ''; // adding '' in case of vd

export const WIDGET_TEXT_CONSTANTS = {
  PAYOUT_AFTER: 'Payout after:',
};

export const CUSTOMER_FORM_FIELD_NAMES = {
  MAX_UNITS: 'maxUnits',
  REQUESTED_AMOUNT: 'requestedAmount',
  REQUESTED_TERM: 'requestedTerm',
};

export const TWO_LEVEL_DECIMAL = 2;

export const PAYMENT_OPTION_CTA_COMPACT_TEXT = 'Pmt options';

// max abount of buttons to show in the footer based on breakpoints
export const PROGEN_FOOTER_CTA_COUNT_MAP = {
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3,
};

export const PROGEN_CTA_SLUG_TO_PERMISSION_SLUG_MAP = {
  create_order: { resource: RESOURCE.order, action: ACTION.create },
  download_proposal: { resource: RESOURCE.order, action: ACTION.download_proposal },
  invite_reseller: { resource: RESOURCE.reseller, action: ACTION.invite },
  approve: { resource: RESOURCE.order, action: ACTION.approve },
  review_and_sign: { resource: RESOURCE.order, action: ACTION.counter_sign_document },
  provide_information: {
    resource: RESOURCE.payment,
    action: ACTION.upload_funding_invoice,
  },
  review_and_approve: { resource: RESOURCE.order, action: ACTION.approve_purchase_order },
  download_statement: {
    resource: RESOURCE.order,
    action: ACTION.download_payoff_statement,
  },
  update_rates: { resource: RESOURCE.customer, action: ACTION.refresh_pricing },
  generate_new_terms: { resource: RESOURCE.customer, action: ACTION.refresh_pricing },
  resend_request: { resource: RESOURCE.customer, action: ACTION.update },
  request_from_customer: { resource: RESOURCE.customer, action: ACTION.update },
  upload_information: { resource: RESOURCE.customer, action: ACTION.update },
  get_agreement: { resource: RESOURCE.order, action: ACTION.download_agreement },
};

export const PROGEN_FOOTER_ACTION_TYPES = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
};

export const MAX_OPTIONS_COUNT_FOR_INTEREST_RATE_RADIO_MODE = {
  sm: 3,
  md: 4,
  lg: 4,
  xl: 4,
};
