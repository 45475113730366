import { AlertMessage, Card, StatusPill, Typography } from '@vartanainc/design-system';
import { Ref, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import OutlinedPill from '../../../../components/Pill/OutlinedPill/OutlinedPill';
import { WidgetContext } from '../../../../context/WidgetContext';
import { TCV_BELOW_APPROVED_ERROR } from '../../../Orders/order.constants';
import LoaderMd from '../../../../components/LoaderMd/LoaderMd';
import { WidgetMetaContext } from '../../../../context/WidgetMetaContext/WidgetMetaContext';
import {
  showErrorToast,
  isZero,
  removeTrailingZeros,
  getPageUrl,
} from '../../../../utils/helpers';
import TabsSummary from '../TabsSummary/TabsSummary';
import '../../../CommonWidgetV2/CommonWidget.scss';
import {
  TertiaryActions,
  getPillStatus,
  isStatusPage,
  renderPgStatus,
  renderFeeTooltip,
  handlePageNavigate,
  isCompactScreen,
  isSmallScreen,
  getPayoutContainerClassNames,
} from '../widgetUtils';
import Calculator from '../Calculator/Calculator';
import { PROGEN_CTA } from '../../../../constants/common.constants';
import { APP_NAMES } from '../../../CommonWidgetV2/commonWidget.constants';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import { CurrencyPill } from '../../../../components/Pill/CurrencyPill';
import { sessionVar } from '../../../../graphql/cache';
import VartanaLoader from '../../../../components/VartanaLoader/VartanaLoader';

interface DeferPaymentTabProps {
  status: string;
  tertiaryActions: {
    label: string;
    type: string;
    slug: string;
  }[];
  setActive: (tab: string) => void;
  setSubPage: (page: string) => void;
  toggleModal: (action) => void;
  isCrm: boolean;
  companyNumber: string | undefined;
  crmOpportunityId: string | undefined;
  handleCancelCreditCheckChangeRequest: () => void;
  calculatorRef: Ref<unknown>;
  setCanDownloadProposal: (canDownloadProposal: boolean) => void;
  showSubPage: boolean;
  setShowSubPage: (showSubPage: boolean | ((prev: boolean) => boolean)) => void;
  setHasCalculatorError: (hasCalculatorError: boolean) => void;
  handleGetHigherLimit: () => void;
  creatingChangeRequest: boolean;
  appName: string | null;
  currency: string;
  isTabLoading: boolean;
}

interface ErrorProps {
  message: string;
  subsidy: string;
  spiff: string;
}

const DeferPaymentTab = ({
  status,
  tertiaryActions,
  setActive,
  setSubPage,
  toggleModal,
  isCrm,
  crmOpportunityId = '',
  companyNumber = '',
  handleCancelCreditCheckChangeRequest,
  setCanDownloadProposal,
  calculatorRef,
  showSubPage,
  setShowSubPage,
  setHasCalculatorError,
  handleGetHigherLimit,
  creatingChangeRequest,
  appName,
  currency,
  isTabLoading,
}: DeferPaymentTabProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();
  const [error, setError] = useState<ErrorProps>();
  // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
  const [vendorFee, setVendorFee] = useState('$0');
  const [discountTooltip, setDiscountTooltip] = useState('');
  const [documentationFee, setDocumentationFee] = useState('');

  const [spiffRate, setSpiffRate] = useState('');
  // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
  const [spiffAmount, setSpiffAmount] = useState('$0');
  const [showSpiff, setShowSpiff] = useState(false);
  const [buyerInterestRate, setBuyerInterestRate] = useState<string>('');

  const navigate = useNavigate();
  const location = useLocation();
  const metaContext = useContext(WidgetMetaContext);
  const widgetContext = useContext(WidgetContext);

  const widthVariant = get(widgetContext, 'widthVariant', '');

  const sessionData = useReactiveVar(sessionVar);
  const showCurrencyPill = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled'
  );

  const shouldShowInterestRateToVendor = get(
    sessionData,
    'session.user.company.product.showRateToVendor'
  );

  const resetTab = useCallback(() => {
    setResponse(undefined);
    setError(undefined);
    // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
    setVendorFee('$0');
    setDiscountTooltip('');
    setDocumentationFee('');
    setCanDownloadProposal(false);
  }, [setCanDownloadProposal]);

  useEffect(() => {
    // Reset tab state when navigating back to main page (calculator) from any subpage (summary, credit details, etc.)
    if (!showSubPage) resetTab();
  }, [resetTab, showSubPage]);

  useEffect(() => {
    setCanDownloadProposal(false);
  }, [setCanDownloadProposal]);

  useEffect(() => {
    setActive('deferPayment');
    setSubPage('');
    setShowSubPage(false);
  }, [setActive, setShowSubPage, setSubPage]);

  const toggleSubPage = (): void => {
    if (showSubPage) setSubPage('');
    setShowSubPage((prev) => !prev);
  };

  const handleTertiaryButtonClick = (action): void => {
    if (action.slug === PROGEN_CTA.modifyTerms) {
      if (isCrm) {
        const url = getPageUrl({
          customerNumber: companyNumber,
          otherParams: location.search,
          page: 'modifyTerms',
          expandedApp: true,
        });
        handlePageNavigate(url, appName === APP_NAMES.HUBSPOT, navigate);
      } else
        navigate(
          getPageUrl({
            customerNumber: companyNumber,
            page: 'modifyTerms',
          })
        );
    } else if (action.slug === PROGEN_CTA.creditInfo) {
      setSubPage('creditDetails');
      toggleSubPage();
    } else if (action.slug === PROGEN_CTA.submitInformation) {
      setSubPage('moreInformationDetails');
      toggleSubPage();
    } else if (action.slug === PROGEN_CTA.viewCreditDecision) {
      toggleModal(action);
    } else if (action.slug === PROGEN_CTA.viewDecisionDetail) {
      toggleModal(action);
    } else if (action.slug === PROGEN_CTA.learnMore) {
      toggleModal(action);
    } else if (action.slug === PROGEN_CTA.trackRequest) {
      setSubPage('trackRequestDetails');
      toggleSubPage();
    } else if (action.slug === PROGEN_CTA.cancelRequest) {
      handleCancelCreditCheckChangeRequest();
    } else {
      showErrorToast();
      reportError(`Unidentified action: ${JSON.stringify(action)}`);
    }
  };

  const handleValueChange = (resp): void => {
    // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
    const totalFormattedFee = get(resp, 'total_formatted_fee', '$0').replace('.00', '');
    const totalSpiffRate = get(resp, 'spiff_rate', '');
    const totalSpiffAmount = get(resp, 'spiff_amount', '$0');
    const discountRate = get(resp, 'formatted_discount_rate_tooltip', '')
      .replace('(', '')
      .replace(')', ' or ')
      .toLowerCase();
    const calculatedBuyerInterestRate = get(resp, 'buyer_interest_rate');
    const docFee = removeTrailingZeros(
      get(resp, 'vendor_application_fee_tooltip', '')
    ).toLowerCase();
    setResponse(resp);
    setError(isEmpty(resp.errors) ? null : resp.errors);
    // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
    setVendorFee(totalFormattedFee || '$0');
    setDiscountTooltip(discountRate || '$0 or 0% subsidy');
    setDocumentationFee(docFee || '$0 documentation fee');
    setHasCalculatorError(!isEmpty(resp.errors));
    setSpiffRate(totalSpiffRate);
    setSpiffAmount(totalSpiffAmount);
    setBuyerInterestRate(`${calculatedBuyerInterestRate} rate`);
  };

  useEffect(() => {
    setShowSpiff(!isZero(spiffAmount));
  }, [spiffAmount]);

  const hasFee =
    get(response, 'vendor_application_fee_tooltip') ||
    get(response, 'formatted_discount_rate_tooltip') ||
    spiffRate === '0';

  const renderSpiffAmount = showSpiff && get(response, 'spiff_amount', false);

  const shouldRenderInterestRatePill =
    shouldShowInterestRateToVendor && !!buyerInterestRate && get(response, 'payment');

  const buyerPayoutText = useMemo(() => {
    const payoutText = get(response, 'payment', '');
    return payoutText.split('/');
  }, [response]);
  const isCustomSchedule = get(metaContext, 'meta.isCustomSchedule', false);

  return (
    <>
      <VartanaLoader loading={isTabLoading} fullscreen={false} />
      <div className="defer-payment-tab w-full">
        {isStatusPage(status) || showSubPage ? (
          <TabsSummary
            status={status}
            onBackClick={toggleSubPage}
            onButtonClick={handleTertiaryButtonClick}
            isCrm={isCrm}
            companyNumber={companyNumber}
            appName={appName}
          />
        ) : (
          <div className="flex flex-col defer-tab-container w-[25.125rem] gap-4 h-[19.25rem]">
            <div className="flex flex-col gap-3">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row gap-2">
                  <StatusPill
                    status={getPillStatus(status)}
                    text={status}
                    compact={isCompactScreen(widthVariant, isCrm)}
                  />
                  {showCurrencyPill && <CurrencyPill compactPill currency={currency} />}
                </div>
                <TertiaryActions
                  actions={tertiaryActions}
                  onActionClick={handleTertiaryButtonClick}
                />
              </div>

              <Card
                padding="compact"
                parentContainerClassName="progen-card"
                content={(
                  <Calculator
                    onValueChange={handleValueChange}
                    setLoading={setIsLoading}
                    calculatorRef={calculatorRef}
                    setCanDownloadProposal={setCanDownloadProposal}
                  />
                )}
              />
            </div>
            <div
              className={getPayoutContainerClassNames(
                !!error,
                isLoading,
                isCustomSchedule
              )}
            >
              {isLoading ? (
                <LoaderMd />
              ) : (
                <>
                  {error ? (
                    <div className="text-left space-y-2 error-message-container">
                      {get(error, 'subsidy') ? (
                        <AlertMessage
                          content={get(error, 'subsidy').replace('.00', '')}
                          type="error"
                        />
                      ) : null}

                      {get(error, 'message') || get(error, 'amount', '') ? (
                        <div className="text-left">
                          <AlertMessage
                            content={removeTrailingZeros(
                              get(error, 'message', '') || get(error, 'amount', '')
                            )}
                            hyperLinkContent={
                              get(error, 'amount', '').includes('value is above') ||
                              get(error, 'amount', '').includes(TCV_BELOW_APPROVED_ERROR)
                                ? 'Click here to get this limit approved'
                                : ''
                            }
                            type="error"
                            onHyperLinkClick={handleGetHigherLimit}
                            hyperLinkDisabled={creatingChangeRequest}
                          />
                          {creatingChangeRequest && (
                            <div className="w-full flex justify-center mt-5">
                              <LoaderMd />
                            </div>
                          )}
                        </div>
                      ) : null}
                      {get(error, 'spiff', false) ? (
                        <div>
                          <AlertMessage
                            content={get(error, 'spiff').replace('.00', '')}
                            type="error"
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="flex flex-col gap-2 mb-4">
                      <div className="w-full flex flex-row gap-4 self-stretch">
                        <div className="flex flex-col items-center w-[calc(100%-2.063rem)]">
                          <Typography
                            variant="paragraph10"
                            color={
                              get(response, 'payment')
                                ? 'color-steel-140'
                                : 'color-gray-110'
                            }
                            className="payout-primary-text"
                          >
                            Buyer pays
                          </Typography>

                          {get(response, 'payment') ? (
                            <div
                              className={`payout-payment-text mt-1 ${
                                shouldShowInterestRateToVendor ? 'mb-1.5' : 'mb-1'
                              }`}
                            >
                              <Typography
                                variant="heading22"
                                color="color-blue-180"
                                className="payout-amount-text"
                              >
                                {buyerPayoutText[0]}
                              </Typography>
                              {shouldRenderInterestRatePill && (
                                <Typography
                                  variant="paragraph12"
                                  color="color-blue-180"
                                  bold
                                  className="payout-amount-text-small"
                                >
                                  {`/${buyerPayoutText[1]}`}
                                </Typography>
                              )}
                            </div>
                          ) : (
                            <SvgIcon
                              className="transform rotate-90"
                              name="more_vert"
                              height="2rem"
                              width="2rem"
                              fill="#D9D9D9"
                            />
                          )}
                          {shouldRenderInterestRatePill && (
                            <div className="mt-1">
                              <OutlinedPill text={buyerInterestRate} variant="primary" />
                            </div>
                          )}
                          {!shouldRenderInterestRatePill && (
                            <Typography
                              variant="paragraph10"
                              color={
                                get(response, 'payment')
                                  ? 'color-steel-140'
                                  : 'color-gray-110'
                              }
                              className="payout-primary-text"
                            >
                              per invoice
                            </Typography>
                          )}
                          {renderPgStatus(
                            get(metaContext, 'meta.calculator.pgRequired', false),
                            `mt-2.5 ${
                              isSmallScreen(widthVariant, isCrm) ? ' translate-x-2' : ''
                            }`,
                            get(response, 'remittance')
                              ? 'color-steel-140'
                              : 'color-gray-110',
                            isSmallScreen(widthVariant, isCrm)
                          )}
                        </div>
                        <div className="bg-gray-300 w-[0.125rem] h-full" />
                        <div className="flex flex-col items-center w-[calc(100%-2.063rem)]">
                          <Typography
                            variant="paragraph10"
                            color={
                              get(response, 'remittance')
                                ? 'color-steel-140'
                                : 'color-gray-110'
                            }
                            className="payout-primary-text"
                          >
                            Seller receives
                          </Typography>

                          {get(response, 'remittance') ? (
                            <div className="payout-payment-text mt-1 mb-1.5">
                              <Typography
                                variant="heading22"
                                color="color-blue-180"
                                className="payout-amount-text"
                              >
                                {get(response, 'remittance')}
                              </Typography>
                            </div>
                          ) : (
                            <SvgIcon
                              className="transform rotate-90"
                              name="more_vert"
                              height="2rem"
                              width="2rem"
                              fill="#D9D9D9"
                            />
                          )}
                          <div className="flex gap-[0.12rem] items-center">
                            <Typography
                              variant="paragraph10"
                              className="payout-primary-text"
                              color={
                                get(response, 'remittance')
                                  ? 'color-steel-140'
                                  : 'color-gray-110'
                              }
                            >
                              {get(metaContext, 'meta.calculator.spiffMode') !== 'none'
                                ? 'total payout'
                                : `after ${vendorFee} fee`}
                            </Typography>
                            {hasFee &&
                              renderFeeTooltip(
                                documentationFee,
                                discountTooltip,
                                isSmallScreen(widthVariant, isCrm)
                              )}
                          </div>
                          {renderSpiffAmount && (
                            <OutlinedPill
                              text={`${get(response, 'spiff_amount', '')} SPIFF`}
                              variant="secondary"
                              containerClassName="mt-2.5"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default DeferPaymentTab;
